/* eslint-disable */
if (self._envConfig.isChurnZeroEnabled && self._envConfig.churnZeroAppKey) {
  self.ChurnZero = self.ChurnZero || [];
  (function () {
    var cz = document.createElement('script');
    cz.type = 'text/javascript';
    cz.async = true;
    cz.src = 'https://eu1analytics.churnzero.net/churnzero.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(cz, s);
    self.ChurnZero.push(['setAppKey', self._envConfig.churnZeroAppKey]);
  })();
}
